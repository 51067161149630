@import 'aui-sidebar-config.less';

//
// Imported by aui-sidebar.
//
// Implements the base container styles for the sidebar, so it works
// on various screen widths and devices. The sidebar has an expanded
// and contracted mode, whose skeleton structure is styled here.
//

//
// The sidebar widths
// managed by CSS variables for great fun and profit!
//
.aui-page-sidebar {
    --aui-sidebar-width: @aui-sidebar-width;

    &.aui-sidebar-collapsed {
        --aui-sidebar-width: @aui-sidebar-collapsed-width;
    }
}

//
// The sidebar skeleton
//
.aui-sidebar {
    #aui.box-sizing();
    min-width: @aui-sidebar-collapsed-width;
    position: absolute;

    // when the sidebar sits over the content
    &.aui-sidebar-fly-out {
        --aui-sidebar-width: @aui-sidebar-width;

        .aui-page-sidebar-touch & {
            border-right: 1px solid @aui-sidebar-border-color;
            height: 100%;
            position: absolute;
        }
    }

    //
    // The content container inside the sidebar.
    //
    .aui-sidebar-wrapper {
        #aui.box-sizing();
        border-color: @aui-sidebar-border-color;
        background-color: @aui-sidebar-background-color;
        display: flex;
        flex-direction: column;
        width: var(--aui-sidebar-width);

        border-inline-end: @aui-border-width @aui-border-type var(--aui-border);

        // The sidebar sits within its container in fun ways.
        position: absolute;
        top: 0;
        bottom: auto;
        // explicit z-index needed in order to appear over the top of page layout's #content and #footer on the sidebar's RHS.
        // they use padding-left to "make space" for sidebar, meaning their background-color bleeds over the top of sidebar.
        z-index: 1;

        &.aui-is-docked {
            position: fixed;
        }
    }

    // Adjustments for mobile devices
    .aui-page-sidebar-touch & {
        background: var(--aui-body-background);
        float: left;

        .aui-sidebar-wrapper {
            border-right: none;
            position: static;
        }
    }

    // Tweak existing page-header when shown inside the sidebar
    .aui-page-header {
        margin: (@aui-sidebar-spacing) (@aui-sidebar-spacing) 0 (@aui-sidebar-spacing);
        padding-bottom: 0;
        position: relative;

        .aui-page-header-image {
            position: absolute;
        }

        .aui-avatar {
            display: block;
        }
    }

    .aui-page-header-inner,
    .aui-page-header-image,
    .aui-page-header-main {
        display: block;
    }

    .aui-page-header-image {
        + .aui-page-header-main {
            padding-left: (@aui-sidebar-grid + @aui-avatar-size-large);
        }
    }

    .aui-sidebar-header-large {
        .aui-page-header-image {
            width: @aui-avatar-size-xxxlarge;
            margin: 0 auto;
            position: inherit;

            + .aui-page-header-main {
                padding-top: @aui-sidebar-grid;
                padding-left: 0;
            }
        }

        .aui-page-header-main {
            text-align: center;

            > h1,
            > h2 {
                #aui.typography.h700();
                padding-top: @aui-sidebar-grid;

                &:only-child {
                    padding-top: 0;
                }
            }
        }
    }

    .aui-page-header-main {
        padding-top: (@aui-avatar-size-large - (@aui-sidebar-base-line-height * 2)) / 2; // avatar - 2 lines of heading text divided by 2 for top and bottom padding to center it vertically

        > h1,
        > h2 {
            #aui.typography.h500();
            #aui.text-truncate();
            line-height: @aui-sidebar-base-line-height;

            &:only-child {
                line-height: @aui-avatar-size-large;
            }
        }
    }

    .aui-page-header-actions {
        display: none; // page header actions should be rendered as a list under the main <nav class="aui-navgroup-vertical">
    }

    .aui-nav-breadcrumbs > li {
        width: 100%;
    }
    // END page header tweaks

    // optional handle to adjust the width of the sidebar
    .aui-sidebar-handle {
        @sidebar-handle-width: @aui-sidebar-grid;
        background: @aui-panel-bg-color url(images/icons/sidebar/icon-handle.png) center center no-repeat;
        cursor: col-resize;
        height: 100%;
        position: absolute;
        right: -@sidebar-handle-width;
        top: 0;
        width: @sidebar-handle-width;
    }

    //
    // Sidebar body and footer content containers.
    //

    .aui-sidebar-body {
        #aui.box-sizing(content-box);
        flex-grow: 1; // so the footer is pushed to the bottom of the sidebar container.
        overflow-y: auto;
    }

    .aui-sidebar-footer {
        #aui.box-sizing();
        border-top: 1px solid @aui-sidebar-border-color;
        display: flex;
        flex-direction: row-reverse;
        flex-shrink: 0; // so the items don't get smaller as the browser height shrinks.
        justify-content: space-between;
        min-height: @aui-sidebar-footer-height;
        padding: (@aui-sidebar-spacing - @aui-sidebar-gutter) @aui-sidebar-gutter;

        .aui-page-sidebar-touch & {
            border-bottom: 1px solid @aui-sidebar-border-color;
            margin-top: @aui-sidebar-grid;
        }

        .aui-button {
            #aui.text-truncate();
            max-width: 100%;

            ~ .aui-button {
                margin-left: 0;
            }
        }

        > .aui-sidebar-toggle {
            order: -1;
        }
    }

    //-----------------------
    // Sidebar narrow state - add collapsed styles.
    // Note: the width is "automatically" shrunk thanks to the CSS variable.
    //-----------------------
    &[aria-expanded="false"] {
        .aui-sidebar-body {
            overflow-x: hidden; // don't show horizontal scrollbar in collapsed state.
        }

        .aui-page-header-inner .aui-page-header-image {
            align-content: center;
            display: flex;
            justify-content: center;
            position: relative;
            width: auto;
        }

        .aui-page-header {
            margin-left: 0;
            margin-right: 0;

            .aui-avatar,
            .aui-avatar .aui-avatar-inner {
                height: @aui-sidebar-header-avatar-collapsed;
                width: @aui-sidebar-header-avatar-collapsed;
            }

            .aui-avatar .aui-avatar-inner > img {
                max-height: @aui-sidebar-header-avatar-collapsed;
                max-width: @aui-sidebar-header-avatar-collapsed;
            }
        }

        .aui-page-header-main {
            display: none;
        }

        .aui-sidebar-body {
            #aui.box-sizing();
            bottom: @aui-sidebar-footer-height * 2;
            padding: 0;
            width: 100%;
        }

        // Adjust the sidebar footer for the collapsed state
        .aui-sidebar-footer {
            overflow: hidden;
            flex-direction: column-reverse;

            > .aui-sidebar-toggle {
                > .aui-icon {
                    #aui.rotate(180deg);
                }
            }

            > .aui-button {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
                // override the text-overflow truncation on aui-button when sidebar is collapsed
                text-overflow: clip;
                white-space: normal;

                > .aui-button-label {
                    display: none;
                }
            }
        }
    }
    // END Collapsed state

    // Animations
    &.aui-is-animated {
        .aui-sidebar-wrapper {
            #aui.transition(width @aui-sidebar-default-transition-timing ease-in-out);
        }

        .aui-sidebar-body {
            #aui.transition(padding-top @aui-sidebar-default-transition-timing ease-in-out);
        }
    }
}
// END Sidebar
//-----------------------

// Page Layout + Sidebar integrations
#content .aui-sidebar ~ [class^="aui-"],
#content .aui-sidebar + section,
#content .aui-sidebar + main,
#content + #footer {
    .aui-page-sidebar & {
        // needs to be padding, not margin, so that horizontal scrollbars are not drawn.
        // future iterations of page layout should use grid or flexbox and carve out explicit space for sidebar.
        padding-left: var(--aui-sidebar-width);
    }

    .aui-page-sidebar.aui-is-animated & {
        #aui.transition(padding-left @aui-sidebar-default-transition-timing ease-in-out);
    }
}

.aui-sidebar + .aui-page-panel {
    border-top-width: 0;
    margin-top: 0;
}
